<template>
  <v-card class="mx-auto" max-width="400" outlined style="">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{
        language.componentLabel.titleChangePass
      }}</v-toolbar-title>
    </v-toolbar>
    <v-spacer></v-spacer>
    <form
      ref="changePassword"
      action="/?f=administration&f2=userSelfService&f3=changePassword"
      method="post"
    >
      <v-list-item>
        <v-list-item-content>
          <div class="body-2">
            <v-row>
              <v-col>
                <fieldsSingleRows
                  field="oldPassword"
                  :templateContent="result"
                  :valueLine="result.valueLine"
                  @field-value-changed="updateFieldValue(...arguments)"
                ></fieldsSingleRows>
                <fieldsSingleRows
                  field="c_password"
                  :templateContent="result"
                  :valueLine="result.valueLine"
                  @field-value-changed="updateFieldValue(...arguments)"
                ></fieldsSingleRows>
                <fieldsSingleRows
                  field="checkPassword"
                  :templateContent="result"
                  :valueLine="result.valueLine"
                  @field-value-changed="updateFieldValue(...arguments)"
                ></fieldsSingleRows>
              </v-col>
            </v-row>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="max-auto">
        <fieldsButtons
          :result="result"
          @submitted="submitForm(...arguments)"
        ></fieldsButtons>
      </v-card-actions>
    </form>
  </v-card>
</template>
<script>
import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";

export default {
  components: {
    fieldsSingleRows,
    fieldsButtons,
  },
  props: ["result"],
  data: function () {
    return {};
  },
  methods: {
    submitForm(functionName) {
      this.ajaxSubmit(functionName, this.$refs.changePassword, "", this.result);
      this.$notify({
        group: "foo",
        text: "Successfully Updated!!",
        duration: 1000,
        type: "success",
        speed: 600,
      });
    },

    updateFieldValue(field, value) {
      let valueLine = { ...this.result.valueLine };
      this.$set(valueLine, field, value);
      this.$set(this.result, "valueLine", valueLine);

      this.$store.commit("resultChanged", this.result);
    },
  },
};
</script>